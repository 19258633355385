<template>
  <svg width="45" height="43" viewBox="0 0 45 43" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M11.3622 24.2638C12.8389 24.2638 14.2275 24.254 15.6064 24.2638C16.379 24.2737 16.7995 24.5987 16.8777 25.2192C16.9657 25.9972 16.5159 26.5192 15.6749 26.5291C14.0906 26.5488 12.5162 26.5389 10.9319 26.5389C10.4723 26.5389 10.0225 26.5586 9.56286 26.5291C8.99567 26.4995 8.43825 26.3813 8.16443 25.7904C7.89062 25.1995 8.0862 24.6676 8.45781 24.1851C9.96381 22.2547 11.4796 20.3145 12.9954 18.3841C13.0834 18.2659 13.1714 18.1477 13.2594 18.0394C13.1616 17.872 13.0247 17.9507 12.9073 17.9409C11.8512 17.931 10.7853 17.9409 9.72911 17.931C9.48463 17.931 9.24015 17.9015 9.00545 17.8523C8.44803 17.7242 8.10576 17.1628 8.23289 16.6113C8.3209 16.237 8.61428 15.9317 8.98589 15.8431C9.22059 15.784 9.46507 15.7545 9.70955 15.7545C11.4502 15.7446 13.2007 15.7446 14.9414 15.7545C15.2544 15.7446 15.5575 15.784 15.8607 15.8628C16.5257 16.0893 16.8092 16.7098 16.5452 17.3598C16.3105 17.9212 15.9389 18.4038 15.5673 18.8864C14.2862 20.5311 13.0051 22.166 11.7241 23.7911C11.6165 23.9191 11.5187 24.0472 11.3622 24.2638Z"
        fill="currentColor"
    />
    <path
        d="M22.7054 19.0805C22.9401 18.7752 23.1846 18.4896 23.5855 18.4108C24.3581 18.2532 25.0818 18.7555 25.0915 19.5434C25.1209 21.5132 25.1111 23.4829 25.0915 25.4527C25.0915 25.9648 24.759 26.4179 24.2799 26.5656C23.7909 26.7528 23.2335 26.605 22.9108 26.1815C22.7445 25.9747 22.6761 25.9353 22.4414 26.1224C21.5515 26.8512 20.5442 26.9793 19.4587 26.6247C17.718 26.0535 17.0041 24.6845 16.8086 23.02C16.6032 21.2177 17.1997 19.6813 18.8035 18.7358C20.1335 17.938 21.483 18.007 22.7054 19.0805ZM19.2436 22.6753C19.2631 23.1087 19.4 23.5223 19.6543 23.867C20.1824 24.5762 21.1896 24.7239 21.9035 24.1921C22.0209 24.1034 22.1284 23.9951 22.2262 23.867C22.7739 23.1185 22.7739 21.8874 22.2262 21.1389C21.9524 20.7548 21.5221 20.5283 21.0625 20.5184C19.9868 20.4495 19.2338 21.2867 19.2436 22.6753ZM29.4824 22.7344C29.4042 20.2033 31.0569 18.3123 33.4039 18.2434C35.8976 18.1646 37.7165 19.8487 37.7947 22.3109C37.873 24.8027 36.3572 26.5656 34.02 26.802C31.4676 27.0581 29.4433 25.1966 29.4824 22.7344ZM31.937 22.4981C31.9174 22.9905 32.0641 23.4731 32.3575 23.8769C32.8953 24.586 33.9026 24.7239 34.6067 24.1724C34.7143 24.0936 34.8023 23.9951 34.8903 23.8966C35.4575 23.1481 35.4575 21.8874 34.9001 21.1389C34.6263 20.7647 34.196 20.5283 33.7364 20.5184C32.6802 20.4593 31.937 21.267 31.937 22.4981ZM28.6218 20.7745C28.6218 22.3011 28.6316 23.8277 28.6218 25.3542C28.6316 26.0535 28.084 26.6346 27.3897 26.6543C27.2723 26.6543 27.1452 26.6444 27.0278 26.6149C26.5389 26.4868 26.1673 25.9648 26.1673 25.3444V17.5145C26.1673 17.0517 26.1575 16.5986 26.1673 16.1357C26.177 15.3774 26.6562 14.8849 27.3799 14.8849C28.1231 14.8751 28.6218 15.3675 28.6218 16.1554C28.6316 17.6918 28.6218 19.2381 28.6218 20.7745Z"
        fill="currentColor"
    />
  </svg>
</template>

<script setup lang="ts"></script>

<style></style>
